import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa6";
const Footer = () => {
    return (
        <>
            <div className="section_8 bg_color py-5">
                <div className="container px-5">
                    <hr className="text-light" />
                    <div className="row align-items-center">
                        <div className="col-md-2 mt-4 text-center">
                            <img src={require('../assets/img/Logo.png')} width={'150px'} alt="" />
                        </div>
                        <div className="col-md-8 mt-4 text-light text-center">
                            <span className="fw-200">© 2024, Cavin Infotech Pvt. Ltd. All Rights Reserved.</span>
                        </div>
                        <div className="col-md-2 mt-4 text-light ">
                            <div className="social_media text-light float-md-end text-center">
                                <Link to='https://in.linkedin.com/company/cavin-infotech' className="text-light" target="_blank"><FaLinkedin className="item" /></Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer