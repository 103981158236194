import React, { useEffect, useState } from "react";
import { FaPlay } from "react-icons/fa6";

import { Link } from "react-router-dom";

const TimeCount = ({container , size , title}) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft() {
        // time 1:30
        const setDate = new Date('April 3, 2024 13:29:59').getTime();
        const currentDate = new Date().getTime();
        const timeleft = setDate - currentDate;

        if (timeleft <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    }

    function addZero(value) {
        return value < 10 ? `0${value}` : value;
    }

    if (timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
        return (
            <Link to={'https://www.youtube.com/watch?v=-y4QeaH3e4U'} target="_blank" className="btn btn-outline-light rounded-pill"> <FaPlay />  Watch live Video</Link>
        )
    }

    return (
        <div className="mt-4 text-center text-light" id={container}>
            <p className="p_13 text-light">{title}</p>
            <div className="time_count countdown d-flex justify-content-center">
                <div className="day ">
                    <h1 className={` ${size} fw-bold time`}>{addZero(timeLeft.days)}</h1>
                    <p>Days</p>
                </div>
                <h1 className=" px-3 time">:</h1>
                <div className="hou">
                    <h1 className={` ${size} fw-bold time`}>{addZero(timeLeft.hours)}</h1>
                    <p>Hours</p>
                </div>
                <h1 className=" px-3 time">:</h1>
                <div className="min">
                    <h1 className={` ${size} fw-bold time`}>{addZero(timeLeft.minutes)}</h1>
                    <p>Minutes</p>
                </div>
                <h1 className=" px-3 time">:</h1>
                <div className="sec">
                    <h1 className={` ${size} fw-bold time`}>{addZero(timeLeft.seconds)}</h1>
                    <p>Seconds</p>
                </div>
            </div>
        </div>
    );
};

export default TimeCount;
