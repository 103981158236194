import React from "react";
import { useEffect } from "react";

const Overview = () => {
    useEffect(() => {
        const handleScroll = () => {
            var parallaxImg = document.querySelector('.shape_1');
            // var parallaxImg2 = document.querySelector('.parallax-img2');
            
            let value = window.scrollY;
            parallaxImg.style.left = value * -0.2 + 'px';
            // parallaxImg2.style.marginTop = value * 1.5 + 'px';
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <>
            <div className="section_2 bg_color">
                <div className="shape_1">
                    <img src={require('../assets/img/shape_1.png')} alt="" />
                </div>
                <div className="container pt-5" id="overview">
                    <div className="row">
                        <div className="col-md-7 px-md-5  text-light">
                            <p className="p_32">Overview</p>
                            <h1 className="display-5 fw-bold">Delivering The Next<br></br> Digital Decade</h1>
                            <p className="p_17 mt-4 fw-300">An event like no other, where creativity knows no bounds and possibilities are limitless. At Kaleidoscope, innovation takes center stage as we bring together industry leaders, visionaries, and forward-thinkers to forge invaluable connections and explore groundbreaking ideas.</p>
                            <p className="p_17 mt-4 fw-300">Your ideas are at the heart of Kaleidoscope, and your creativity and knowledge matter to us. Join the conversation as we exchange insights, spark new ideas, and celebrate the power of collaboration.</p>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-3  text-light overview_content d-flex align-items-center">
                            <div className="row">
                                <div className="content d-flex">
                                      <h1>4</h1>
                                      <p className="pt-3 p_21"> Products</p>
                                </div>
                                <div className="content d-flex">
                                      <h1>6</h1>
                                      <p className="pt-3 p_21"> Keynote Speakers</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="pb-5" id="agenda"></div>

            </div>
        </>
    )
}

export default Overview