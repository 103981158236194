import React from "react";
import { useEffect } from "react";

const Agenda = () => {
   useEffect(() => {
      const handleScroll = () => {
         var shape_2 = document.querySelector('.shape_2');
         var shape_3 = document.querySelector('.shape_3');
         var shape_4 = document.querySelector('.shape_4');

         let value = window.scrollY;
         shape_2.style.left = value * 1.1 + 'px';
         shape_3.style.left = value * -0.1 + 'px';
         shape_4.style.left = value * 0.7 + 'px';
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, []);

   return (
      <>
         <div className="section_4 bg_color mt-3 py-4 overflow-x-hidden" >
            <div className="shape_2">
               <img src={require('../assets/img/shape_2.png')} width={'55%'} alt="" />
            </div>
            <div className="shape_3">
               <img src={require('../assets/img/shape_3.png')} width={'60%'} alt="" />
            </div>
            <div className="shape_4">
               <img src={require('../assets/img/shape_4.png')} width={'54%'} alt="" />
            </div>
            <div className="text-light text-center pt-4">
               <h1 className="display-5 fw-bold">Agenda</h1>
               <p className="p_18 p_color">3rd April 2024 - 2 PM Onwards</p>
            </div>
            <div className="container pt-5">
               <div className="row justify-content-center align-items-center text-light px-md-5 px-sm-0">
                  <div className="col-md-7">
                     <p className="ps-5 p_22 fw-bold">Title</p>
                  </div>
                  <div className="col-md-5 ">
                     <p className="item_end p_22 fw-bold">Speaker</p>
                  </div>
                  <hr />
                  <div className="col-md-7">
                     <p className="ps-5 p_22">Welcome Address Speech</p>
                  </div>
                  <div className="col-md-5">
                     <p className=" item_end p_19">Saravanan M <br /><span className="p_16 p_color">Head - Cavin Infotech Pvt. Ltd.</span></p>
                  </div>
                  <hr />
                  <div className="col-md-7">
                     <p className="ps-5 p_22">Innovation on Products</p>
                  </div>
                  <div className="col-md-5">
                     <p className="item_end p_19">Praburaju S<br /><span className="p_16 p_color">Head - Products & Support</span></p>
                  </div>
                  <hr />
                  <div className="col-md-7">
                     <p className="ps-5 p_22">AI Advancements</p>
                  </div>
                  <div className="col-md-5">
                     <p className=" item_end p_19">Robin D <br /><span className="p_16 p_color">Head - AI/ML & Data Engineering</span></p>
                  </div>
                  <hr />
                  <div className="col-md-7">
                     <p className="ps-5 p_22">Digital Transformation Journey</p>
                  </div>
                  <div className="col-md-5">
                     {/* <p className=" item_end p_19">Aishwaryaa B & Anand Kumar<br /> Delivery Manager Head - Technology</p> */}
                     <div className="d-flex item_end"><div className="pe-0">
                           <p className="p_19 mb-2">Aishwaryaa B</p>
                           <p className="p_16 p_color">Head - Development</p>
                        </div>
                        <div>
                           <p className="pe-3 ps-3 p_15"> & </p>
                        </div>
                        <div>
                           <p className="p_19 mb-2">Anand Kumar J</p>
                           <p className="p_16 p_color">Head - Technology</p>
                        </div>
                     </div>
                  </div>
                  <hr />
                  <div className="col-md-7">
                     <p className="ps-5 py-2 p_22">Keynote Speech </p>
                  </div>
                  <div className="col-md-5">
                     <p className=" item_end p_19">CKPL Leaders</p>
                  </div>
                  <hr />
                  <div className="col-md-7">
                     <p className="ps-5 py-2 p_22">R & R Award Ceremony</p>
                  </div>
                  <div className="col-md-5">
                     <p className=" item_end p_19 pb-0">---</p>
                  </div>
                  <hr />
                  <div className="col-md-7">
                     <p className="ps-5 py-2 p_22">Cultural Activities</p>
                  </div>
                  <div className="col-md-5">
                     <p className=" item_end p_19">CITPL Team</p>
                  </div>
                  <hr />
                  <div className="col-md-7">
                     <p className="ps-5 py-2 p_22">Special Address Speech</p>
                  </div>
                  <div className="col-md-5">
                     <p className=" item_end p_19">Usha Guru </p>
                  </div>
                  <hr />
                  <div className="col-md-7">
                     <p className="ps-5 py-2 p_22">Address by the Guest of Honour</p>
                  </div>
                  <div className="col-md-5">
                     <p className=" item_end p_19">CK Ranganathan </p>
                  </div>
                  {/* <hr />
                  <div className="col-md-7">
                     <p className="ps-5 py-2 p_22">Expression of Gratitude Address </p>
                  </div>
                  <div className="col-md-5">
                     <p className=" item_end p_19">Thiruvenkada Ashwin V A <br /> <span className="p_16 p_color">Product Manager</span></p>
                  </div>
                  <hr /> */}
                  <hr />

               </div>
            </div>
         </div>
      </>
   )
}

export default Agenda