import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { useState , useEffect} from "react";
import TimeCount from "./Time_counter";

const Navbar = () => {
    const[menuClick , setmenuClick] = useState(false);

    const menuHandle = () => {
        setmenuClick(true);
    }

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };

      const [scroled, setSrolled] = useState(false);
      const scrollHandle = () => {
      let timeCount1 = document.querySelector('.time-navbar');

        if (window.scrollY > 100) {
          setSrolled(true);
          timeCount1.style.display = 'block';
        } else {
          setSrolled(false);
          timeCount1.style.display = 'none';
        }
      }

      useEffect(() => {
        window.addEventListener('scroll', scrollHandle)
        return () => {
          window.removeEventListener('scroll', scrollHandle)
        }
      }, [])

    return (
        <>
            <nav className={`navbar navbar-expand-lg fixed-top ${scroled ? 'nav-bgColor' : 'p-4'}  ${menuClick ? 'menuColor' : ''}`}>
                <div className="container-fluid">
                    <Link className='navbar-brand text-light ms-md-5'>
                        <img src={require('../assets/img/Logo.png')} width={'150px'} alt="" />
                    </Link>
                    <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <AiOutlineMenu onClick={menuHandle} style={{color : 'white'}}/>
                    </button>

                    <div class="collapse navbar-collapse justify-content-center" id="navbarCollapse">
                        <ul className='navbar-nav '>
                            <li className="nav-item px-2">
                                <Link className="nav-link text-light" onClick={() => scrollToSection('overview')}>Overview</Link>
                            </li>
                            <li className="nav-item px-2">
                                <Link className="nav-link text-light" onClick={() => scrollToSection('agenda')}>Agenda</Link>
                            </li>
                            <li className="nav-item px-2">
                                <Link className="nav-link text-light" onClick={() => scrollToSection('r&r')}>R & R</Link>
                            </li>
                            <li className="nav-item px-2">
                                <Link className="nav-link text-light" onClick={() => scrollToSection('immersive')}>Immersive</Link>
                            </li>
                            <li className="nav-item px-2">
                                <Link className="nav-link text-light" onClick={() => scrollToSection('about')}>About</Link>
                            </li>
                            <li className="nav-item px-2">
                                <Link className="nav-link btn nav_btn text-light px-4" onClick={() => scrollToSection('venue')}>Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="me-5 time-navbar">
                        <TimeCount container={'time_container1'}/>
                    </div>
                </div>
            </nav>
        </>
    )
}


export default Navbar