import React from "react";
import data from "./data";


const Immersive = () => {
    return (
        <>
            <div className="section_5 pt-5" id="immersive">
                <div className="container mt-5">
                    <h1 className="text-center text-light imm_heading fw-bold pt-5 display-5">Immersive Experience Zones</h1>
                    <div className="row justify-content-center gx-5">
                        {
                            data.Immersive.map((item, index) => {
                                return (
                                    <div className="col-lg-3 col-md-6 col-sm-6 text-light mt-4 mt-lg-5" key={index}>
                                            <img src={item.img} alt="" width={'180px'} className="mx-auto d-block img-fluid" />
                                        <div className="content text-center">
                                            <p className="p_17 fw-200 ">{item.text}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Immersive