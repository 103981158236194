import React from "react";
import { SlLocationPin } from "react-icons/sl";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Venue = () => {
    useEffect(() => {
        const handleScroll = () => {
            // var parallaxContainer = document.querySelector('.venue_bg');
            // var scrolled = window.pageYOffset;
            // var rate = 1 + (scrolled / 2000);
            // parallaxContainer.style.backgroundSize = rate * 35 + '%';
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    })
    return (
        <>
            {/* <div className="my-5" ></div> */}

                <div className="section_6 bg_color pt-3" id="venue">
                    <div className="container mt-5 ">
                        <div className="row justify-content-center text-light">
                            <div className="col-md-12 venue_bg p-5">
                                <div className="text-center p-2">
                                    <h1 className=" fw-bold display-5 pb-3">Venue</h1>
                                    <p className="fw-bold p_24 pt-3 mb-2">CITPL Office</p>
                                    <p className="p_18 fw-200">8th Floor, Tower B, TECCI PARK,173, Old Mahabalipuram Road, Elcot <br /> Sez, Sholinganallur, Chennai, Tamil Nadu 600119</p>
                                    <Link to={'https://www.google.com/maps?rlz=1C1JJTC_enIN1060IN1060&gs_lcrp=EgZjaHJvbWUqFQgDEC4YJxivARjHARiABBiKBRiOBTIGCAAQRRg7MhMIARAuGIMBGMcBGLEDGNEDGIAEMgYIAhBFGDkyFQgDEC4YJxivARjHARiABBiKBRiOBTIGCAQQRRg8MgYIBRBFGDwyBggGEEUYQTIGCAcQRRhB0gEINDA1OGowajeoAgiwAgE&um=1&ie=UTF-8&fb=1&gl=in&sa=X&geocode=Kb9Dv0MmW1I6MXtMpLWhjkpK&daddr=B-Block,8th+Floor,+TECCI+PARK,+SH+49A,+Elcot+Sez,+Karapakkam,+Chennai,+Tamil+Nadu+600119'} target="_blank" className="btn location_btn mb-4 mt-0"><SlLocationPin /> View Location</Link>
                                    <p className="fw-bold pt-3 p_24 mb-2">Contact</p>
                                    <p className="p_20 mb-2 fw-400">Adeline Christina</p>
                                    <p className="p_18 mb-2 fw-300">adeline.i@cavininfotech.com</p>
                                    <p className="p_18 fw-300">Contact number : 9894161560</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        </>
    )
}

export default Venue