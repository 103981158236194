import React from "react";

const data = {
    special_event : [
        {
            title : 'Praburaju S',
            para : 'Head - Product'
        },
        {
            title : 'Anand Kumar J',
            para : 'Head - Technology'
        },
        {
            title : 'Robin David',
            para : 'Head - Data'
        },
        {
            title : 'Aishwaryaa B',
            para : 'Delivery Manager'
        },
    ],
    Immersive:[
        {
            img : require('../assets/img/Imm_img1.png'),
            text : 'Revolutionizing FMCG Operations with Streamlined Sales & Distribution Management.',
        },
        {
            img : require('../assets/img/Imm_img2.png'),
            text : 'Budgie streamlines HR operations, simplifying everything from recruitment to payroll processes. ',
        },
        {
            img : require('../assets/img/Imm_img3.png'),
            text : 'All in one knowledge management portal and Internal connecting Hub.',
        },
        {
            img : require('../assets/img/Imm_img4.png'),
            text : 'Elevate success with Objectives and Key Results.',
        },
        {
            img : require('../assets/img/Imm_img5.png'),
            text : 'Conversational Al model,capable of generating human-like responses to text inputs across a wide range of topics & contexts.',
        },
        {
            img : require('../assets/img/Imm_img6.png'),
            text : 'Integrated with smart factory technology, offers solution for real-time machine visbility & monitoring.',
        },
        {
            img : require('../assets/img/Imm_img7.png'),
            text : 'Powerful tool to enhance production efficiency by identifying & improving avilability, performance & quality.',
        }
    ],
    about:[
        {
            title : 'Innovation',
            para : 'We thrive on pushing boundaries, constantly seeking new and creative ways to solve challenges.'
        },
        {
            title : 'Integrity',
            para : 'Honesty and transparency are the foundations of our relationships with clients, partners, and each other.'
        },
        {
            title : 'Collaboration',
            para : 'We believe in the power of teamwork, fostering a culture that encourages diverse perspectives and collective success.'
        }
    ],
    Rewards:[
        {
            img : require('../assets/img/rewards_img_1.png'),
            title : 'Best Individual',
            para : 'Performer'
        },
        {
            img : require('../assets/img/rewards_img_2.png'),
            title : 'Best Budding',
            para : 'Performer'
        },
        {
            img : require('../assets/img/rewards_img_3.png'),
            title : 'Best Cross Functional Team' ,
            para : 'Performer' ,
            padding : 'pt-3'
        }
    ]
}


export default data;