import React from "react";
import Navbar from "./Navbar";
import Time_count from "./Time_counter";
import banner_img3 from '../assets/img/banner_img_3.png';
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Banner = () => {
    useEffect(() => {

        const handleScroll = () => {
            const parallaxImg = document.querySelector('.parallax-img');
            
            let multiplierImg = 1.5;
            let multiplierTime = 2.5;

            if (window.innerWidth <= 768) {
                multiplierImg = 0; 
                multiplierTime = 0;
            }

            let value = window.scrollY;
            parallaxImg.style.marginTop = value * multiplierImg + 'px';
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>            
            <div className="section_1">
            <Navbar />
                <div className="container mt-5">
                <div className="banner_Shape">
                    <img src={require('../assets/img/banner_shape.png')} width={'100%'} alt="" />
                </div>
                    <div className="text-center banner_content">
                        <img src={require('../assets/img/cavi_logo.png')} className="parallax-img cavin_logo" alt="" />
                        <br />
                        <img src={banner_img3} className="banner_logo parallax-img" alt="my img" />

                        <div className="mt-3 mb-4">
                            <h1 className="text-light display-5 fw-bold banner_title parallax-img">Extravaganza of Tech Innovation</h1>
                            <p className="text-light">3rd April 2024 | CITPL, Chennai</p>
                        </div>
                        <Time_count size={'display-5'} title={'YOU ONLY HAVE TO WAIT'}/>
                    </div>
                </div>    
                {/* <div className="container_space" id=""></div>             */}
            </div>
        </>
    )
}


export default Banner