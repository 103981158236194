import React from "react";
import { Routes ,Route } from 'react-router-dom';
import Banner from "./pages/Banner";
import Overview from "./pages/Overview";
import Event_special from "./pages/Event_special";
import Immersive from "./pages/Immersive";
import Agenda from "./pages/Agenda";
import Venue from "./pages/Venue";
import About from "./pages/About";
import Footer from "./pages/Footer";
import Rewards from "./pages/Rewards";

const App = () => {
    return(
        <>
            <Banner /> 
            <Overview />
            {/* <Event_special />   */}
            <Agenda/>
            <Rewards/>            
            <Immersive /> 
            <About /> 
            <Venue /> 
            <Footer /> 
        </>
    )
}

export default App