import React from "react";
import data from "./data";
import { useEffect } from "react";
import { useState } from "react";

const About = () => {
    const [scale, setScale] = useState(0.9);

    useEffect(() => {
        const handleScroll = () => {
            // var shape_5 = document.querySelector('.shape_5');          
            // var shape_6 = document.querySelector('.shape_6');           
            // var about_img = document.querySelector('.about_img');           
  
            let value = window.scrollY;
            const scrolled = window.pageYOffset;
            console.log(scrolled);
            const newScale = 0.5 + (scrolled / 4000); 
            setScale(newScale);

            // shape_6.style.left = value * 0.2 + 'px';
        };
  
        window.addEventListener('scroll', handleScroll);
  
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <>
            <div className="section_7 bg_color overflow-x-hidden pb-5" id="about">
                <div className="shape_5">
                    <img src={require('../assets/img/shape_5.png')} width={'50%'} alt="" />
                </div>
                <div className="shape_6">
                    <img src={require('../assets/img/shape_6.png')} width={'50%'} alt="" />
                </div>
                <div className="container ">
                    <h1 className="text-center text-light fw-bold display-5">About Cavin Infotech</h1>
                        <div className="row justify-content-center align-items-center mt-5 gx-5">
                            <div className="col-md-6 text-light">
                                <p className="about_content"> At Cavin Infotech, we are digital pioneers with a passion for innovation. With deep roots in FMCG, and expertise in engineering B2B and B2C SaaS products, we are a dynamic force redefining the boundaries of creativity and technology.</p>
                            </div>
                            <div className="col-md-6 overflow-hidden about_img">
                                <img src={require('../assets/img/about.png')} alt="" className="img-fluid " style={{ transform: `scale(${scale})` }}/>
                            </div>
                        </div>
                        <div className="row mt-3 gx-4 mb-md-5">
                            {
                                data.about.map((item , index) => {
                                    return(
                                        <div className="col-md-4 text-light" key={index}>
                                            <h4 className="fw-bold about_sub_title pb-2">{item.title}</h4>
                                            <p className="pb-md-5 p_16 fw-300">{item.para}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                </div>
            </div>
        </>
    )
}


export default About