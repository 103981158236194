import React from "react";
import data from "./data";
import { useEffect } from "react";

const Rewards = () => {
    return(
        <>
            <div className="section_9 bg_color mb-5 mt-5 pt-5" id="r&r">
                <div className="container ">
                    <div className="text-light text-center">
                        <h1 className="display-5 fw-bold">Rewards & Recognitions</h1>
                    </div>
                    <div className="row mt-5">
                        {
                            data.Rewards.map((item , index) => {
                                return(
                                    <div className="col-md-4 mt-4 text-center text-light" key={index}>
                                        <img src={item.img} alt=""className={item.padding} />
                                        <h4 className="text_gradiant pt-4">{item.title}</h4>
                                        <h4 className="text_gradiant">{item.sub_title}</h4>
                                        <p className="p_15 rewards_item p_22">{item.para}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}


export default Rewards